import logout from "../../../assets/svg-icons/logoutIcon.svg";
import securityGroups from "../../../assets/svg-icons/securityGroupSolid.svg";
import resources from "../../../assets/svg-icons/resources.svg";
import manage from "../../../assets/svg-icons/edit.svg";
import billing from "../../../assets/svg-icons/blackBilling.svg";
import support from "../../../assets/svg-icons/blackSupport.svg";
import setting from "../../../assets/svg-icons/blackSetting.svg";
import dispute from "../../../assets/svg-icons/paymentDispute.svg";
import { Link, useLocation } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();
  const activeSidebarItem =
    "flex items-center gap-3 bg-primaryBlue/20 text-primaryBlue p-4 rounded-md text-xs";
  const idleSidebarItem =
    "flex items-center gap-3 text-primaryBlue p-4 rounded-md text-xs";
  return (
    <div className="lg:col-span-2 bg-white dark:bg-[#141D26] border-r dark:border-r-[#c4c4c432] dark:text-white hidden lg:flex">
      <div className="fixed px-4">
        <div className="py-6">
          <div className="space-y-[2px] grid">
            <Link to={"/client-area"} className="grid">
              <div
                className={`${location.pathname === "/client-area" ? activeSidebarItem : idleSidebarItem}`}
              >
                <img src={resources} alt="" className="h-4" />
                <div className="text-center truncate">Resources</div>
              </div>
            </Link>
            {/* <Link to={"/client-area/activity"} className="grid">
                <div className={`${location.pathname === "/client-area/activity" ? activeSidebarItem : idleSidebarItem}`}>
                  <img src={activity} alt="" className="h-4" />
                  <div className="text-center truncate">Activity</div>
                </div>
              </Link> */}
            <Link to={"/client-area/manage"} className="grid">
              <div
                className={`${location.pathname === "/client-area/manage" ? activeSidebarItem : idleSidebarItem}`}
              >
                <img src={manage} alt="" className="h-4" />
                <div className="text-center truncate">Manage project</div>
              </div>
            </Link>
            <Link to={"/securityGroups"} className="grid">
              <div
                className={`${location.pathname === "/securityGroups" ? activeSidebarItem : idleSidebarItem}`}
              >
                <img src={securityGroups} alt="" className="h-4" />
                <div className="text-center truncate">Security groups</div>
              </div>
            </Link>
          </div>
          <div className="space-y-[2px] my-3 border-y border-[#c4c4c432] grid">
            <Link to={"/billing"} className="grid">
              <div
                className={`${location.pathname === "/billing" ? activeSidebarItem : idleSidebarItem}`}
              >
                <img src={billing} alt="" className="h-4" />
                <div className="text-center truncate">Billing</div>
              </div>
            </Link>
            <Link to={"/help"} className="grid">
              <div
                className={`${location.pathname === "/help" ? activeSidebarItem : idleSidebarItem}`}
              >
                <img src={support} alt="" className="h-4" />
                <div className="text-center truncate">Support</div>
              </div>
            </Link>
            <Link
              to={"/help"}
              onClick={() =>
                sessionStorage.setItem("supportType", "payment-dispute")
              }
              className="grid"
            >
              <div className="flex items-center gap-3 text-primaryBlue p-4 rounded-md text-xs">
                <img src={dispute} alt="" className="h-4" />
                <div className="text-center truncate">Payment dispute</div>
              </div>
            </Link>
            <Link to={"/settings"} className="grid">
              <div
                className={`${location.pathname === "/settings" ? activeSidebarItem : idleSidebarItem}`}
              >
                <img src={setting} alt="" className="h-4" />
                <div className="text-center truncate">Settings</div>
              </div>
            </Link>
          </div>
          <Link
            to={"/sign-out"}
            className="flex gap-3 items-center text-white p-4 rounded hover:bg-primaryBlue/5"
          >
            <img src={logout} alt="" className="h-4" />
            <span className="text-xs text-primaryBlue hidden md:block">
              Sign out
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
