import Select from "../../../components/select";

const ReservedIP = () => {
  const options = [
    { value: "dew-1", label: "Dew one" },
    { value: "dew-2", label: "Dew two" },
    { value: "dew-3", label: "Dew three" },
    { value: "dew-4", label: "Dew four" },
  ];
  return (
    <div>
      <p className="dark:text-white text-sm mb-1">Setup reserved IPs:</p>
      <p className="dark:text-gray-500 text-gray-400 text-sm mb-6 leading-loose md:leading-normal">
        Reserved IP addresses are specific IP ranges designated for private
        networks and special uses
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <Select
          label="Select a dew to assign your reserved IP"
          name="services"
          options={options}
        />
      </div>
    </div>
  );
};

export default ReservedIP;
