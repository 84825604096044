const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div className="border-t dark:border-t-slate-800 text-white py-6 bg-black px-4 md:px-10 text-sm grid grid-cols-1 lg:grid-cols-2 gap-8 items-end">
      <div className="grid gap-2">
        <div className="text-xs font-medium">
          Copyright ©{currentYear} CloudVantage.
        </div>
        <div className="text-xs opacity-60">
          2, Dele Omodara Close off Titilayo Adedoyin Street, Omole Phase 1,
          Lagos.
        </div>
      </div>
      <div className="flex lg:justify-end gap-8 opacity-50 text-xs">
        <div>Terms and conditions</div>
        <div>Privacy policy</div>
      </div>
    </div>
  );
};

export default Footer;
