import { Link } from "react-router-dom";
import Input from "../../../components/input";
import { useFormik } from "formik";
import Select from "../../../components/select";
import { useServerList } from "../layout/resuableEffect";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createPasswordResetLink } from "../../../hooks/local/userReducer";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";

const SendPasswordResetLink = () => {
  document.title = "Reset password - CloudVantage";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);

  const createPasswordReset = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required("Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { email } = values;
      let passwordResetFormData = {
        email,
      };
      const { payload } = await dispatch(
        createPasswordResetLink({ passwordResetFormData, navigate })
      );
      if (payload.statuscode === "00") {
        resetForm();
        navigate("/sign-in");
      }
    },
  });

  return (
    <div className="px-4 flex justify-center items-center py-8">
      {/* Form */}
      <div className="my-3 w-full md:w-[50%] lg:w-[40%] ">
        <form onSubmit={createPasswordReset.handleSubmit}>
          <div className="bg-white dark:bg-[#141D26] border border-[#c4c4c432] dark:text-white rounded-md px-6 py-10 grid gap-6">
            <div>
              <p className="pb-1 crimson-font text-lg font-semibold">
                Reset password
              </p>
              <p className="text-xs text-[#c4c4c4] pb-2 border-b dark:border-b-[#c4c4c432]">
                Regain access to your account
              </p>
            </div>

            <Input
              label="Email address"
              name="email"
              value={createPasswordReset.values.email}
              onBlur={createPasswordReset.handleBlur}
              onChange={createPasswordReset.handleChange}
              placeholder={"Enter email address"}
              onError={
                createPasswordReset.touched.email &&
                createPasswordReset.errors.email
              }
            />
            <div className="grid md:flex md:justify-between gap-6 md:gap-0 items-center">
              <Button
                buttonRole={"submit"}
                loading={loading}
                buttonText={"  Send password reset link"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendPasswordResetLink;
