export const countryCodes = [
  {
    name: "Afghanistan",
    short_code: "AF",
    code: "+93",
  },
  {
    name: "Albania",
    short_code: "AL",
    code: "+355",
  },
  {
    name: "Algeria",
    short_code: "DZ",
    code: "+213",
  },
  {
    name: "AmericanSamoa",
    short_code: "AS",
    code: "+1 684",
  },
  {
    name: "Andorra",
    short_code: "AD",
    code: "+376",
  },
  {
    name: "Angola",
    short_code: "AO",
    code: "+244",
  },
  {
    name: "Anguilla",
    short_code: "AI",
    code: "+1 264",
  },
  {
    name: "Antarctica",
    short_code: "AQ",
    code: "+672",
  },
  {
    name: "Antigua and Barbuda",
    short_code: "AG",
    code: "+1268",
  },
  {
    name: "Argentina",
    short_code: "AR",
    code: "+54",
  },
  {
    name: "Armenia",
    short_code: "AM",
    code: "+374",
  },
  {
    name: "Aruba",
    short_code: "AW",
    code: "+297",
  },
  {
    name: "Australia",
    short_code: "AU",
    code: "+61",
  },
  {
    name: "Austria",
    short_code: "AT",
    code: "+43",
  },
  {
    name: "Azerbaijan",
    short_code: "AZ",
    code: "+994",
  },
  {
    name: "Bahamas",
    short_code: "BS",
    code: "+1 242",
  },
  {
    name: "Bahrain",
    short_code: "BH",
    code: "+973",
  },
  {
    name: "Bangladesh",
    short_code: "BD",
    code: "+880",
  },
  {
    name: "Barbados",
    short_code: "BB",
    code: "+1 246",
  },
  {
    name: "Belarus",
    short_code: "BY",
    code: "+375",
  },
  {
    name: "Belgium",
    short_code: "BE",
    code: "+32",
  },
  {
    name: "Belize",
    short_code: "BZ",
    code: "+501",
  },
  {
    name: "Benin",
    short_code: "BJ",
    code: "+229",
  },
  {
    name: "Bermuda",
    short_code: "BM",
    code: "+1 441",
  },
  {
    name: "Bhutan",
    short_code: "BT",
    code: "+975",
  },
  {
    name: "Bolivia, Plurinational State of Bolivia",
    short_code: "BO",
    code: "+591",
  },
  {
    name: "Bosnia and Herzegovina",
    short_code: "BA",
    code: "+387",
  },
  {
    name: "Botswana",
    short_code: "BW",
    code: "+267",
  },
  {
    name: "Bouvet Island",
    short_code: "BV",
    code: "+55",
  },
  {
    name: "Brazil",
    short_code: "BR",
    code: "+55",
  },
  {
    name: "British Indian Ocean Territory",
    short_code: "IO",
    code: "+246",
  },
  {
    name: "Brunei Darussalam",
    short_code: "BN",
    code: "+673",
  },
  {
    name: "Bulgaria",
    short_code: "BG",
    code: "+359",
  },
  {
    name: "Burkina Faso",
    short_code: "BF",
    code: "+226",
  },
  {
    name: "Burundi",
    short_code: "BI",
    code: "+257",
  },
  {
    name: "Cambodia",
    short_code: "KH",
    code: "+855",
  },
  {
    name: "Cameroon",
    short_code: "CM",
    code: "+237",
  },
  {
    name: "Canada",
    short_code: "CA",
    code: "+1",
  },
  {
    name: "Cape Verde",
    short_code: "CV",
    code: "+238",
  },
  {
    name: "Cayman Islands",
    short_code: "KY",
    code: "+1345",
  },
  {
    name: "Central African Republic",
    short_code: "CF",
    code: "+236",
  },
  {
    name: "Chad",
    short_code: "TD",
    code: "+235",
  },
  {
    name: "Chile",
    short_code: "CL",
    code: "+56",
  },
  {
    name: "China",
    short_code: "CN",
    code: "+86",
  },
  {
    name: "Christmas Island",
    short_code: "CX",
    code: "+61",
  },
  {
    name: "Cocos (Keeling) Islands",
    short_code: "CC",
    code: "+61",
  },
  {
    name: "Colombia",
    short_code: "CO",
    code: "+57",
  },
  {
    name: "Comoros",
    short_code: "KM",
    code: "+269",
  },
  {
    name: "Congo",
    short_code: "CG",
    code: "+242",
  },
  {
    name: "Congo, The Democratic Republic of the",
    short_code: "CD",
    code: "+243",
  },
  {
    name: "Cook Islands",
    short_code: "CK",
    code: "+682",
  },
  {
    name: "Costa Rica",
    short_code: "CR",
    code: "+506",
  },
  {
    name: "Ivory Coast",
    short_code: "CI",
    code: "+225",
  },
  {
    name: "Croatia",
    short_code: "HR",
    code: "+385",
  },
  {
    name: "Cuba",
    short_code: "CU",
    code: "+53",
  },
  {
    name: "Cyprus",
    short_code: "CY",
    code: "+357",
  },
  {
    name: "Czech Republic",
    short_code: "CZ",
    code: "+420",
  },
  {
    name: "Denmark",
    short_code: "DK",
    code: "+45",
  },
  {
    name: "Djibouti",
    short_code: "DJ",
    code: "+253",
  },
  {
    name: "Dominica",
    short_code: "DM",
    code: "+1 767",
  },
  {
    name: "Dominican Republic",
    short_code: "DO",
    code: "+1 849",
  },
  {
    name: "Ecuador",
    short_code: "EC",
    code: "+593",
  },
  {
    name: "Egypt",
    short_code: "EG",
    code: "+20",
  },
  {
    name: "El Salvador",
    short_code: "SV",
    code: "+503",
  },
  {
    name: "Equatorial Guinea",
    short_code: "GQ",
    code: "+240",
  },
  {
    name: "Eritrea",
    short_code: "ER",
    code: "+291",
  },
  {
    name: "Estonia",
    short_code: "EE",
    code: "+372",
  },
  {
    name: "Ethiopia",
    short_code: "ET",
    code: "+251",
  },
  {
    name: "Falkland Islands",
    short_code: "FK",
    code: "+500",
  },
  {
    name: "Faroe Islands",
    short_code: "FO",
    code: "+298",
  },
  {
    name: "Fiji",
    short_code: "FJ",
    code: "+679",
  },
  {
    name: "Finland",
    short_code: "FI",
    code: "+358",
  },
  {
    name: "France",
    short_code: "FR",
    code: "+33",
  },
  {
    name: "French Polynesia",
    short_code: "PF",
    code: "+689",
  },
  {
    name: "French Southern and Antarctic Lands",
    short_code: "TF",
    code: "+262",
  },
  {
    name: "Gabon",
    short_code: "GA",
    code: "+241",
  },
  {
    name: "Gambia",
    short_code: "GM",
    code: "+220",
  },
  {
    name: "Georgia",
    short_code: "GE",
    code: "+995",
  },
  {
    name: "Germany",
    short_code: "DE",
    code: "+49",
  },
  {
    name: "Ghana",
    short_code: "GH",
    code: "+233",
  },
  {
    name: "Gibraltar",
    short_code: "GI",
    code: "+350",
  },
  {
    name: "Greece",
    short_code: "EL",
    code: "+30",
  },
  {
    name: "Greenland",
    short_code: "GL",
    code: "+299",
  },
  {
    name: "Grenada",
    short_code: "GD",
    code: "+1 473",
  },
  {
    name: "Guadeloupe",
    short_code: "GP",
    code: "+590",
  },
  {
    name: "Guam",
    short_code: "GU",
    code: "+1 671",
  },
  {
    name: "Guatemala",
    short_code: "GT",
    code: "+502",
  },
  {
    name: "Guernsey",
    short_code: "GG",
    code: "+44",
  },
  {
    name: "Guinea",
    short_code: "GN",
    code: "+224",
  },
  {
    name: "Guinea-Bissau",
    short_code: "GW",
    code: "+245",
  },
  {
    name: "Guyana",
    short_code: "GY",
    code: "+592",
  },
  {
    name: "Haiti",
    short_code: "HT",
    code: "+509",
  },
  {
    name: "Heard Island and McDonald Islands",
    short_code: "HM",
    code: "+672",
  },
  {
    name: "Vatican City State (Holy See)",
    short_code: "VA",
    code: "+379",
  },
  {
    name: "Honduras",
    short_code: "HN",
    code: "+504",
  },
  {
    name: "Hong Kong",
    short_code: "HK",
    code: "+852",
  },
  {
    name: "Hungary",
    short_code: "HU",
    code: "+36",
  },
  {
    name: "Iceland",
    short_code: "IS",
    code: "+354",
  },
  {
    name: "India",
    short_code: "IN",
    code: "+91",
  },
  {
    name: "Indonesia",
    short_code: "ID",
    code: "+62",
  },
  {
    name: "Iran, Islamic Republic of",
    short_code: "IR",
    code: "+98",
  },
  {
    name: "Iraq",
    short_code: "IQ",
    code: "+964",
  },
  {
    name: "Ireland",
    short_code: "IE",
    code: "+353",
  },
  {
    name: "Isle of Man",
    short_code: "IM",
    code: "+44",
  },
  {
    name: "Israel",
    short_code: "IL",
    code: "+972",
  },
  {
    name: "Italy",
    short_code: "IT",
    code: "+39",
  },
  {
    name: "Jamaica",
    short_code: "JM",
    code: "+1 876",
  },
  {
    name: "Japan",
    short_code: "JP",
    code: "+81",
  },
  {
    name: "Jersey",
    short_code: "JE",
    code: "+44",
  },
  {
    name: "Jordan",
    short_code: "JO",
    code: "+962",
  },
  {
    name: "Kazakhstan",
    short_code: "KZ",
    code: "+7",
  },
  {
    name: "Kenya",
    short_code: "KE",
    code: "+254",
  },
  {
    name: "Kiribati",
    short_code: "KI",
    code: "+686",
  },
  {
    name: "North Korea",
    short_code: "KP",
    code: "+850",
  },
  {
    name: "South Korea",
    short_code: "KR",
    code: "+82",
  },
  {
    name: "Kuwait",
    short_code: "KW",
    code: "+965",
  },
  {
    name: "Kyrgyzstan",
    short_code: "KG",
    code: "+996",
  },
  {
    name: "Laos",
    short_code: "LA",
    code: "+856",
  },
  {
    name: "Latvia",
    short_code: "LV",
    code: "+371",
  },
  {
    name: "Lebanon",
    short_code: "LB",
    code: "+961",
  },
  {
    name: "Lesotho",
    short_code: "LS",
    code: "+266",
  },
  {
    name: "Liberia",
    short_code: "LR",
    code: "+231",
  },
  {
    name: "Libyan Arab Jamahiriya",
    short_code: "LY",
    code: "+218",
  },
  {
    name: "Liechtenstein",
    short_code: "LI",
    code: "+423",
  },
  {
    name: "Lithuania",
    short_code: "LT",
    code: "+370",
  },
  {
    name: "Luxembourg",
    short_code: "LU",
    code: "+352",
  },
  {
    name: "Macau",
    short_code: "MO",
    code: "+853",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    short_code: "MK",
    code: "+389",
  },
  {
    name: "Madagascar",
    short_code: "MG",
    code: "+261",
  },
  {
    name: "Malawi",
    short_code: "MW",
    code: "+265",
  },
  {
    name: "Malaysia",
    short_code: "MY",
    code: "+60",
  },
  {
    name: "Maldives",
    short_code: "MV",
    code: "+960",
  },
  {
    name: "Mali",
    short_code: "ML",
    code: "+223",
  },
  {
    name: "Malta",
    short_code: "MT",
    code: "+356",
  },
  {
    name: "Marshall Islands",
    short_code: "MH",
    code: "+692",
  },
  {
    name: "Martinique",
    short_code: "MQ",
    code: "+596",
  },
  {
    name: "Mauritania",
    short_code: "MR",
    code: "+222",
  },
  {
    name: "Mauritius",
    short_code: "MU",
    code: "+230",
  },
  {
    name: "Mayotte",
    short_code: "YT",
    code: "+262",
  },
  {
    name: "Mexico",
    short_code: "MX",
    code: "+52",
  },
  {
    name: "Micronesia, Federated States of",
    short_code: "FM",
    code: "+691",
  },
  {
    name: "Moldova, Republic of",
    short_code: "MD",
    code: "+373",
  },
  {
    name: "Monaco",
    short_code: "MC",
    code: "+377",
  },
  {
    name: "Mongolia",
    short_code: "MN",
    code: "+976",
  },
  {
    name: "Montenegro",
    short_code: "ME",
    code: "+382",
  },
  {
    name: "Montserrat",
    short_code: "MS",
    code: "+1664",
  },
  {
    name: "Morocco",
    short_code: "MA",
    code: "+212",
  },
  {
    name: "Mozambique",
    short_code: "MZ",
    code: "+258",
  },
  {
    name: "Myanmar",
    short_code: "MM",
    code: "+95",
  },
  {
    name: "Namibia",
    short_code: "NA",
    code: "+264",
  },
  {
    name: "Nauru",
    short_code: "NR",
    code: "+674",
  },
  {
    name: "Nepal",
    short_code: "NP",
    code: "+977",
  },
  {
    name: "Netherlands",
    short_code: "NL",
    code: "+31",
  },
  {
    name: "Netherlands Antilles",
    short_code: "AN",
    code: "+599",
  },
  {
    name: "New Caledonia",
    short_code: "NC",
    code: "+687",
  },
  {
    name: "New Zealand",
    short_code: "NZ",
    code: "+64",
  },
  {
    name: "Nicaragua",
    short_code: "NI",
    code: "+505",
  },
  {
    name: "Niger",
    short_code: "NE",
    code: "+227",
  },
  {
    name: "Nigeria",
    short_code: "NG",
    code: "+234",
  },
  {
    name: "Niue",
    short_code: "NU",
    code: "+683",
  },
  {
    name: "Norfolk Island",
    short_code: "NF",
    code: "+672",
  },
  {
    name: "Northern Mariana Islands",
    short_code: "MP",
    code: "+1 670",
  },
  {
    name: "Norway",
    short_code: "NO",
    code: "+47",
  },
  {
    name: "Oman",
    short_code: "OM",
    code: "+968",
  },
  {
    name: "Pakistan",
    short_code: "PK",
    code: "+92",
  },
  {
    name: "Palau",
    short_code: "PW",
    code: "+680",
  },
  {
    name: "Palestinian Territory, Occupied",
    short_code: "PS",
    code: "+970",
  },
  {
    name: "Panama",
    short_code: "PA",
    code: "+507",
  },
  {
    name: "Papua New Guinea",
    short_code: "PG",
    code: "+675",
  },
  {
    name: "Paraguay",
    short_code: "PY",
    code: "+595",
  },
  {
    name: "Peru",
    short_code: "PE",
    code: "+51",
  },
  {
    name: "Philippines",
    short_code: "PH",
    code: "+63",
  },
  {
    name: "Pitcairn",
    short_code: "PN",
    code: "+870",
  },
  {
    name: "Poland",
    short_code: "PL",
    code: "+48",
  },
  {
    name: "Portugal",
    short_code: "PT",
    code: "+351",
  },
  {
    name: "Puerto Rico",
    short_code: "PR",
    code: "+1 939",
  },
  {
    name: "Qatar",
    short_code: "QA",
    code: "+974",
  },
  {
    name: "Réunion",
    short_code: "RE",
    code: "+262",
  },
  {
    name: "Romania",
    short_code: "RO",
    code: "+40",
  },
  {
    name: "Russia",
    short_code: "RU",
    code: "+7",
  },
  {
    name: "Rwanda",
    short_code: "RW",
    code: "+250",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    short_code: "SH",
    code: "+290",
  },
  {
    name: "Saint Kitts and Nevis",
    short_code: "KN",
    code: "+1 869",
  },
  {
    name: "Saint Lucia",
    short_code: "LC",
    code: "+1 758",
  },
  {
    name: "Saint Pierre and Miquelon",
    short_code: "PM",
    code: "+508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    short_code: "VC",
    code: "+1 784",
  },
  {
    name: "Samoa",
    short_code: "WS",
    code: "+685",
  },
  {
    name: "San Marino",
    short_code: "SM",
    code: "+378",
  },
  {
    name: "Sao Tome and Principe",
    short_code: "ST",
    code: "+239",
  },
  {
    name: "Saudi Arabia",
    short_code: "SA",
    code: "+966",
  },
  {
    name: "Senegal",
    short_code: "SN",
    code: "+221",
  },
  {
    name: "Serbia",
    short_code: "RS",
    code: "+381",
  },
  {
    name: "Seychelles",
    short_code: "SC",
    code: "+248",
  },
  {
    name: "Sierra Leone",
    short_code: "SL",
    code: "+232",
  },
  {
    name: "Singapore",
    short_code: "SG",
    code: "+65",
  },
  {
    name: "Slovakia",
    short_code: "SK",
    code: "+421",
  },
  {
    name: "Slovenia",
    short_code: "SI",
    code: "+386",
  },
  {
    name: "Solomon Islands",
    short_code: "SB",
    code: "+677",
  },
  {
    name: "Somalia",
    short_code: "SO",
    code: "+252",
  },
  {
    name: "South Africa",
    short_code: "ZA",
    code: "+27",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    short_code: "GS",
    code: "+500",
  },
  {
    name: "Spain",
    short_code: "ES",
    code: "+34",
  },
  {
    name: "Sri Lanka",
    short_code: "LK",
    code: "+94",
  },
  {
    name: "Sudan",
    short_code: "SD",
    code: "+249",
  },
  {
    name: "Suriname",
    short_code: "SR",
    code: "+597",
  },
  {
    name: "Svalbard and Jan Mayen",
    short_code: "SJ",
    code: "+47",
  },
  {
    name: "Swaziland",
    short_code: "SZ",
    code: "+268",
  },
  {
    name: "Sweden",
    short_code: "SE",
    code: "+46",
  },
  {
    name: "Switzerland",
    short_code: "CH",
    code: "+41",
  },
  {
    name: "Syria",
    short_code: "SY",
    code: "+963",
  },
  {
    name: "Taiwan",
    short_code: "TW",
    code: "+886",
  },
  {
    name: "Tajikistan",
    short_code: "TJ",
    code: "+992",
  },
  {
    name: "Tanzania, United Republic of",
    short_code: "TZ",
    code: "+255",
  },
  {
    name: "Thailand",
    short_code: "TH",
    code: "+66",
  },
  {
    name: "Timor-Leste",
    short_code: "TL",
    code: "+670",
  },
  {
    name: "Togo",
    short_code: "TG",
    code: "+228",
  },
  {
    name: "Tokelau",
    short_code: "TK",
    code: "+690",
  },
  {
    name: "Tonga",
    short_code: "TO",
    code: "+676",
  },
  {
    name: "Trinidad and Tobago",
    short_code: "TT",
    code: "+1 868",
  },
  {
    name: "Tunisia",
    short_code: "TN",
    code: "+216",
  },
  {
    name: "Turkey",
    short_code: "TR",
    code: "+90",
  },
  {
    name: "Turkmenistan",
    short_code: "TM",
    code: "+993",
  },
  {
    name: "Turks and Caicos Islands",
    short_code: "TC",
    code: "+1 649",
  },
  {
    name: "Tuvalu",
    short_code: "TV",
    code: "+688",
  },
  {
    name: "Uganda",
    short_code: "UG",
    code: "+256",
  },
  {
    name: "Ukraine",
    short_code: "UA",
    code: "+380",
  },
  {
    name: "United Arab Emirates",
    short_code: "AE",
    code: "+971",
  },
  {
    name: "United Kingdom",
    short_code: "GB",
    code: "+44",
  },
  {
    name: "United States",
    short_code: "US",
    code: "+1",
  },
  {
    name: "United States Minor Outlying Islands",
    short_code: "UM",
    code: "+1581",
  },
  {
    name: "Uruguay",
    short_code: "UY",
    code: "+598",
  },
  {
    name: "Uzbekistan",
    short_code: "UZ",
    code: "+998",
  },
  {
    name: "Vanuatu",
    short_code: "VU",
    code: "+678",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    short_code: "VE",
    code: "+58",
  },
  {
    name: "Vietnam",
    short_code: "VN",
    code: "+84",
  },
  {
    name: "Virgin Islands, British",
    short_code: "VG",
    code: "+1 284",
  },
  {
    name: "Virgin Islands, U.S.",
    short_code: "VI",
    code: "+1 340",
  },
  {
    name: "Wallis and Futuna",
    short_code: "WF",
    code: "+681",
  },
  {
    name: "Western Sahara",
    short_code: "EH",
    code: "+732",
  },
  {
    name: "Yemen",
    short_code: "YE",
    code: "+967",
  },
  {
    name: "Zambia",
    short_code: "ZM",
    code: "+260",
  },
  {
    name: "Zimbabwe",
    short_code: "ZW",
    code: "+263",
  },
];
