import { useEffect, useState } from "react";
import Input from "../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency } from "../../../hooks/local/userReducer";

const Settings = () => {
  document.title = "Settings - Cloudvantage";
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.user.currency);
  const [currencyPTag, setCurrencyPTag] = useState("");
  const [currencyButtonText, setCurrencyButtonText] = useState("");

  useEffect(() => {
    if (currency === "NGN") {
      setCurrencyPTag("Nigerian Naira (₦)");
      setCurrencyButtonText("Switch to Dollar ($)");
    }
    if (currency === "USD") {
      setCurrencyPTag("United States Dollar ($)");
      setCurrencyButtonText("Switch to Naira (₦)");
    }
  }, [currency]);

  const handleCurrencySwitch = () => {
    const newCurrency = currency === "NGN" ? "USD" : "NGN";
    dispatch(setCurrency(newCurrency));
  };

  return (
    <div className="px-4 md:px-10 py-6 min-h-screen dark:text-white">
      <p className="crimson-font text-xl font-medium text-primaryBlue">
        Settings
      </p>
      <div className="w-full lg:w-[70%] my-10">
        {/* TEAM SETTINGS */}
        {/* <div>
          <div className="flex items-end gap-3 mb-6">
            <div className="w-full">
              <Input label={"Team name"} type={"text"} value={"Default team"} />
            </div>
            <div>
              <button className="bg-primaryBlue text-white px-6 p-4 rounded-md mt-3">
                Update
              </button>
            </div>
          </div>
          <div className="mb-6">
            <Input label={"Team email address"} type={"text"} />
          </div>
          <div className="w-full md:w-2/3 lg:w-1/2">
            <div className="grid">
              <Input label={"Dew limit"} type={"text"} value={"25 Dews"} />
            </div>
            <p className="text-gray-500 mt-2 text-sm">
              Please contact admin to request an increase in Dew limit
            </p>
          </div>
        </div> */}

        {/* SWITCH CURRENCY */}
        <div>
          <p>Switch currency:</p>
          <p className="mt-4 text-sm">
            Product prices will be displayed in{" "}
            <span className="underline underline-offset-4">{currencyPTag}</span>
          </p>
          <button
            onClick={handleCurrencySwitch}
            className="bg-primaryBlue text-white text-xs p-5 rounded-md mt-3"
          >
            {" "}
            {currencyButtonText}{" "}
          </button>
        </div>

        <hr className="my-10 dark:border-[#c4c4c432]" />

        {/* UPDATE PASSPWORD */}

        <div className="">
          <p>Update password:</p>
          <div className="grid gap-4">
            <div className="grid grid-cols-1 gap-4 mt-6 ">
              <div className="">
                <Input label={"Old password"} type={"password"} />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-4">
              <div>
                <Input label={"New password"} type={"password"} />
              </div>
              <div>
                <Input label={"Confirm password"} type={"password"} />
              </div>
              <div className="text-sm text-gray-500">
                <p>Your password must contain:</p>
                <ul className="list-disc list-inside">
                  <li>at least 8 characters</li>
                  <li>at least 1 special character</li>
                  <li>at least 1 number</li>
                  <li>at least 1 uppercase alphabet</li>
                </ul>
              </div>
            </div>
          </div>
          <button className="bg-primaryBlue text-white text-sm px-6 py-5 rounded-md mt-6">
            Update password
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
