import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import deleteIcon from "../../../assets/svg-icons/deleteRed.svg";
import close from "../../../assets/svg-icons/closeBrand.svg";
import Input from "../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createSecurityGroup,
  listSecurityGroups,
  deleteSecurityGroup,
} from "../../../hooks/local/userReducer";

const SecurityGroups = () => {
  document.title = "Security groups - CloudVantage";

  const selectedProjectId = useSelector(
    (state) => state.user.selectedProject.id,
  );
  const [isCreateSecurityGroup, setIsCreateSecurityGroup] = useState(false);
  const dispatch = useDispatch();
  const [securityGroupsList, setSecurityGroupsList] = useState([]);

  const refetchData = async () => {
    const { payload } = await dispatch(listSecurityGroups(selectedProjectId));

    setSecurityGroupsList(payload);
  };

  useEffect(() => {
    refetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  const toggleSecurityGroupModal = () => {
    setIsCreateSecurityGroup(!isCreateSecurityGroup);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = securityGroupsList.filter((row) =>
    row.name.toLowerCase().includes(searchText.toLowerCase()),
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const createNewSecurityGroup = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Security group name cannot be empty"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { name, description } = values;
      let securityGroupData = { name, description };
      const { payload } = await dispatch(
        createSecurityGroup({
          securityGroupData,
          projectId: selectedProjectId,
        }),
      );

      if (payload) {
        toggleSecurityGroupModal();
        refetchData();
        resetForm();
      }
    },
  });

  const handleDeleteSecurityGroup = async (securityGroupId) => {
    const { payload } = await dispatch(
      deleteSecurityGroup({ projectId: selectedProjectId, securityGroupId }),
    );
    if (payload) refetchData();
  };

  return (
    <div className="px-4 md:px-10 py-6 min-h-screen">
      <p className="crimson-font text-xl font-medium text-primaryBlue mb-6">
        Security groups
      </p>
      <div className="grid md:flex md:items-center md:justify-between gap-4 mb-4">
        <div className="w-full md:w-1/2 lg:w-1/3">
          <input
            type="search"
            name=""
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search security group..."
            className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
          />
        </div>
        <div
          onClick={toggleSecurityGroupModal}
          className="px-4 py-3 text-xs border-[1.5px] border-primaryBlue rounded-md text-primaryBlue cursor-pointer w-fit"
        >
          Create security group
        </div>
      </div>
      <div className="overflow-x-auto rounded-md dark:text-white">
        <table className="w-full text-sm text-left overflow-hidden">
          <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border dark:border-[#c4c4c432] text-sm font-semibold">
            <tr>
              <th scope="col" className="px-6 py-4 whitespace-nowrap"></th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Name
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Security Group ID
              </th>

              <th scope="col" className="px-6 py-4 whitespace-nowrap w-52"></th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-slate-800 text-black/50 dark:text-white/50 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] text-sm">
            {currentEntries.map((row, index) => (
              <tr
                key={index}
                className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
              >
                <td className="text-start py-4 px-6">
                  {(currentPage - 1) * entriesPerPage + (index + 1)}
                </td>
                <td className="text-start py-4 px-6">{row.name}</td>
                <td className="text-start py-4 px-6">{row.id}</td>
                <td className="text-start py-4 px-6 flex items-center gap-4 w-52">
                  <Link
                    to={`/manageSecurityGroups/${row.id}`}
                    className="text-xs border rounded-md p-2 cursor-pointer"
                  >
                    Manage rules
                  </Link>
                  <div
                    className="w-fit cursor-pointer"
                    onClick={() => handleDeleteSecurityGroup(row.id)}
                  >
                    <img src={deleteIcon} alt="" className="h-4" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="grid justify-center gap-4 md:flex md:justify-between items-center mt-4 dark:text-white">
        <p className="text-gray-400 text-sm">
          Showing{" "}
          <span className="font-medium text-black dark:text-white">
            {indexOfFirstEntry + 1}
          </span>{" "}
          to{" "}
          <span className="font-medium text-black dark:text-white">
            {Math.min(indexOfLastEntry, securityGroupsList.length)}
          </span>{" "}
          of{" "}
          <span className="font-medium text-black dark:text-white">
            {securityGroupsList.length}
          </span>{" "}
          results
        </p>
        <div className=" flex gap-3">
          <button
            className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${
              currentPage === 1 ? "opacity-50" : "opacity-100"
            }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${
              currentPage === totalPages ? "opacity-50" : "opacity-100"
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      {/* add new rule modal */}
      {isCreateSecurityGroup && (
        <div className="fixed top-0 h-screen w-full left-0 z-50 bg-black/60 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-[#141D26] rounded-md p-4 md:p-10 w-full md:w-1/3 max-h-[80%] overflow-y-scroll">
            <div className="flex items-center justify-between mb-4">
              <p className="text-lg text-primaryBlue">Create security group</p>
              <span
                onClick={toggleSecurityGroupModal}
                className="cursor-pointer"
              >
                <img alt="" src={close} className="h-5" />
              </span>
            </div>
            <div>
              {/* Security group name */}
              <div className="grid gap-4">
                <Input
                  type="text"
                  placeholder={"Security group name"}
                  name={"name"}
                  value={createNewSecurityGroup.values.name}
                  onChange={createNewSecurityGroup.handleChange}
                  onBlur={createNewSecurityGroup.handleBlur}
                  onError={
                    createNewSecurityGroup.touched.name &&
                    createNewSecurityGroup.errors.name
                      ? createNewSecurityGroup.errors.name
                      : null
                  }
                />
                <Input
                  type="text"
                  placeholder={"Security group description"}
                  name={"description"}
                  value={createNewSecurityGroup.values.description}
                  onChange={createNewSecurityGroup.handleChange}
                  onBlur={createNewSecurityGroup.handleBlur}
                  onError={
                    createNewSecurityGroup.touched.description &&
                    createNewSecurityGroup.errors.description
                      ? createNewSecurityGroup.errors.description
                      : null
                  }
                />
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    createNewSecurityGroup.handleSubmit(e);
                  }}
                  className="text-xs text-[#1DA1F2] w-full border-[1.5px] border-primaryBlue rounded px-8 py-4 cursor-pointer]"
                >
                  Create group
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SecurityGroups;
