import React, { useContext } from "react";
import { ThemeContext } from "../components/themeContext";
import toggleOff from "../assets/svg-icons/toggleOff.svg";
import toggleOn from "../assets/svg-icons/toggleOn.svg";

const ThemeToggler = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeSwitch = () => {
    // Manually toggle between light and dark themes
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  return (
    <button onClick={handleThemeSwitch}>
      <div className="flex items-center gap-2">
        <img
          src={theme === "dark" ? toggleOn : toggleOff}
          alt="Toggle theme icon"
        />
        <span className="text-sm md:text-xs opacity-50 hidden md:block">
          Toggle theme
        </span>
      </div>
    </button>
  );
};

export default ThemeToggler;
