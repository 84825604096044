import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listDews,
  listServers,
  listSecurityGroups,
} from "../../../hooks/local/userReducer";
import { useNavigate } from "react-router-dom";

export function useListDews() {
  const [listDew, setListDews] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchListOfDew = async () => {
      try {
        const { payload } = await dispatch(listDews({ navigate }));
        setListDews(payload);
      } catch (err) {}
    };
    fetchListOfDew();
  }, [dispatch, navigate]);
  return listDew;
}

export function useServerList() {
  const selectedProject = useSelector((state) => state.user.selectedProject);
  const [userServer, setUserServer] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedProjectId =
      selectedProject && selectedProject.id ? selectedProject.id : null;
    const fetchServers = async () => {
      try {
        const { payload } = await dispatch(listServers(selectedProjectId));
        setUserServer(payload);
      } catch (err) {}
    };
    fetchServers();
  }, [dispatch, selectedProject]);
  return userServer;
}

export function useListSecurityGroups() {
  const [listGroups, setListGroups] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchSecurityGroups = async () => {
      try {
        const { payload } = await dispatch(listSecurityGroups({ navigate }));
        setListGroups(payload);
      } catch (err) {}
    };
    fetchSecurityGroups();
  }, [dispatch, navigate]);
  return listGroups;
}

export function extractDiskSize(diskSize) {
  if (!diskSize || typeof diskSize !== "string") {
    return null;
  }

  const match = diskSize.match(/(\d+(\.\d+)?)/);

  return match ? parseFloat(match[0]) : null;
}
