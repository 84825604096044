import Accordion from "../faqAccordion";

const VPS = () => {
  return (
    <div>
      <Accordion
        Title={"What is a VPS? "}
        Content={
          "A VPS, or Virtual Private Server, is a virtualized server environment that offers dedicated resources like CPU, RAM, and storage. It's like having your own mini server within a shared physical machine, providing more control and flexibility compared to shared hosting."
        }
      />
      <Accordion
        Title={"Why choose Cloudvantage VPS? "}
        Content={
          "Our VPS plans offer scalability, security, and performance that shared hosting can't match. You get root access to customize your server and install applications, perfect for businesses with growing needs."
        }
      />
      <Accordion
        Title={"What VPS plans do you offer?"}
        Content={
          "We offer a variety of VPS plans to suit different needs. Our plans scale in terms of CPU cores, RAM, storage and storage allowing you to choose the resources that fit your website or application perfectly. Cloudvantage also offers a range of operating systems for VPS hosting, including popular options such as Linux distributions (e.g., Ubuntu, CentOS, Fedora)"
        }
      />
      <Accordion
        Title={"Is a VPS easy to manage?"}
        Content={
          "Cloudvantage provides user-friendly control panels and offers optional managed VPS services where we take care of server administration for you. You can focus on running your business while we handle the technical aspects."
        }
      />
      <Accordion
        Title={
          "Can I upgrade or downgrade my VPS plan as my requirements change? "
        }
        Content={
          "Yes, Cloudvantage allows customers to easily upgrade or downgrade their VPS plans based on their evolving needs. Simply contact our support team to discuss your requirements and make the necessary changes."
        }
      />
      <Accordion
        Title={"How does a VPS differ from shared hosting?"}
        Content={
          "Unlike shared hosting, where multiple users share the resources of a single server, a VPS provides dedicated resources for enhanced performance and security."
        }
      />
      <Accordion
        Title={"How can i get started with VPS?"}
        Content={
          "Visit our website to explore our VPS plans and choose the one that best suits your requirements. We offer a user-friendly signup process and provide 24/7 support to help you get started."
        }
      />
    </div>
  );
};

export default VPS;
