import deleteIcon from "../../../assets/svg-icons/deleteRed.svg";
import { useState } from "react";
import attachIcon from "../../../assets/svg-icons/attach.svg";
import detachIcon from "../../../assets/svg-icons/detach.svg";
import close from "../../../assets/svg-icons/closeBrand.svg";

const Volumes = () => {
  const [instanceModal, setInstanceModal] = useState(false);
  const toggleInstanceModal = () => {
    setInstanceModal(!instanceModal);
  }
  
  const volumes = [
    { name: "Volume 1", attachedInstance: "", sizeInGB: 50 },
    { name: "Volume 2", attachedInstance: "Sample instance name", sizeInGB: 100 },
    { name: "Volume 3", attachedInstance: "Sample instance name", sizeInGB: 75 },
    { name: "Volume 4", attachedInstance: "", sizeInGB: 120 },
    { name: "Volume 5", attachedInstance: "", sizeInGB: 200 },
    { name: "Volume 6", attachedInstance: "Another instance name", sizeInGB: 60 },
    { name: "Volume 7", attachedInstance: "", sizeInGB: 80 },
    { name: "Volume 8", attachedInstance: "", sizeInGB: 150 },
    { name: "Volume 9", attachedInstance: "Another instance name", sizeInGB: 300 },
    { name: "Volume 10", attachedInstance: "Another instance name", sizeInGB: 500 },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  // Filter volumes based on search text
  const filteredVolumes = volumes.filter((volume) =>
    volume.name.toLowerCase().includes(searchText)
  );

  // Calculate pagination data
  const totalPages = Math.ceil(filteredVolumes.length / entriesPerPage);
  const startIndex = (currentPage - 1) * entriesPerPage;
  const currentEntries = filteredVolumes.slice(
    startIndex,
    startIndex + entriesPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="text-sm">
      <div className="mb-6 dark:text-white">Manage Volumes</div>
      <div className="grid md:flex md:items-center md:justify-between gap-4 mb-4">
        <div className="w-full md:w-1/2 lg:w-1/3">
          <input
            type="search"
            name=""
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search Volumes..."
            className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
          />
        </div>
        <div className="px-4 py-3 text-xs border-[1.5px] border-primaryBlue rounded-md text-primaryBlue cursor-pointer w-fit">
          Create volume
        </div>
      </div>
      <div className="overflow-x-auto rounded-md dark:text-white">
        <table className="w-full text-sm text-left overflow-hidden">
          <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border dark:border-[#c4c4c432] text-sm font-semibold">
            <tr>
              <th scope="col" className="px-6 py-4 whitespace-nowrap w-5">
                #
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap max-w-52">
                Volume name
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Volume size (GB)
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Attached instance
              </th>
              <th scope="col" className="px-6 py-4 whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-slate-800 text-black/50 dark:text-white/50 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] text-sm">
            {currentEntries.map((row, index) => (
              <tr
                key={index}
                className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
              >
                <td className="text-start py-4 px-6 w-5">
                  {(currentPage - 1) * entriesPerPage + (index + 1)}
                </td>
                <td className="text-start py-4 px-6 truncate max-w-52">
                  {row.name}
                </td>
                <td className="text-start py-4 px-6">{row.sizeInGB}</td>
                <td className="text-start py-4 px-6">
                  {row.attachedInstance === "" ? (
                    <div
                      className="w-fit text-xs border dark:border-[#c4c4c424] rounded-md p-2 cursor-pointer flex items-center gap-1 text-primaryBlue"
                      onClick={toggleInstanceModal}
                    >
                      <img src={attachIcon} alt="Delete" className="h-4" />
                      <span className="pt-[2px]">Attach to instance</span>
                    </div>
                  ) : (
                    <div className="">{row.attachedInstance}</div>
                  )}
                </td>
                <td className="text-start py-4 px-6 flex items-center gap-4">
                  <div className="w-fit truncate text-xs border dark:border-[#c4c4c424] rounded-md p-2 cursor-pointer flex items-center gap-1 text-red-500">
                    <img src={deleteIcon} alt="Delete" className="h-4" />
                    <span className="pt-[2px] truncate">Delete volume</span>
                  </div>
                  {row.attachedInstance === "" ? (
                    <div></div>
                  ) : (
                    <div className="w-fit truncate text-xs border dark:border-[#c4c4c424] rounded-md p-2 cursor-pointer flex items-center gap-1 text-red-500">
                      <img src={detachIcon} alt="Delete" className="h-4" />
                      <span className="pt-[2px] truncate">
                        Detach from instance
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="grid justify-center gap-4 md:flex md:justify-between items-center mt-4 dark:text-white">
        <p className="text-gray-400 text-sm">
          Showing{" "}
          <span className="font-medium text-black dark:text-white">
            {(currentPage - 1) * entriesPerPage + 1}
          </span>{" "}
          to{" "}
          <span className="font-medium text-black dark:text-white">
            {Math.min(currentPage * entriesPerPage, filteredVolumes.length)}
          </span>{" "}
          of{" "}
          <span className="font-medium text-black dark:text-white">
            {filteredVolumes.length}
          </span>{" "}
          results
        </p>
        <div className="flex gap-3">
          <button
            className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${
              currentPage === 1
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${
              currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      {/* instance modal */}
      {instanceModal && (
        <div className="fixed top-0 h-screen w-full left-0 z-50 bg-black/60 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-[#141D26] rounded-md p-4 md:p-10 w-full md:w-1/3 max-h-[80%] overflow-y-scroll">
            <div className="flex items-center justify-between mb-4">
              <p className="text-primaryBlue">Attach to an instance</p>
              <span onClick={toggleInstanceModal} className="cursor-pointer">
                <img alt="" src={close} className="h-5" />
              </span>
            </div>

            <div>
              <select className="py-4 rounded-md border border-[#c4c4c460] focus:outline-0 bg-white dark:bg-slate-800 text-sm w-full">
                <option value="" disabled>
                  Select Instance
                </option>
              </select>

              <div className="mt-6">
                <button
                  type="button"
                  className="bg-[#1DA1F2] text-white text-sm px-6 py-4 rounded-sm"
                >
                  Attach to instance
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Volumes;
