import React, { useContext } from "react";
import { CartContext } from "../../../components/cartContext";
import pattern from "../../../assets/images/bluePattern.svg";
import domainImage from "../../../assets/images/domain registration mockup.png";

const BuyDomain = () => {
  document.title = "Domain Hosting - CloudVantage";

  return (
    <div className="px-4 md:px-10 pt-6 pb-16 min-h-screen">
      <div className="bg-brandGreen rounded-md grid grid-cols-1 lg:grid-cols-5">
        <div className="lg:col-span-3 px-6 md:px-10 py-10">
          <p className="text-lg leading-snug crimson-font font-bold mb-4 w-full lg:w-[70%]">
            <span className="text-[#68A5AE]">Domain Hosting:</span> <br />
            Transform your digital presence
          </p>
          <p className="text-sm">
            Tailored domain registration services to elevate your cloud
            experience!
          </p>
          <div className="mt-6">
            <input
              type="search"
              className="w-full p-4 rounded-md border border-[#c4c4c460] focus:outline-0 placeholder:text-xs"
            />
            <button className="rounded-md bg-black  px-4 py-5 text-xs text-white mt-4">
              Check availability
            </button>
          </div>
        </div>
        <div className="lg:col-span-2 flex items-end overflow-clip">
          <img src={pattern} alt="" className="w-full" />
        </div>
      </div>
      <div className="mt-10 dark:text-white grid gap-16">
        <div>
          <p className="mb-4">Pricing:</p>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="bg-white dark:bg-[#ffffff08] border border-t-[#68A5AE] dark:border-t-[#68A5AE] dark:border-[#c4c4c424] border-t-4 rounded-md p-5 grid gap-4">
              <span className="text-xs bg-brandGreen dark:bg-brandGreen/5 text-[#68A5AE] p-2 rounded w-fit">
                💯 A true internet original
              </span>
              <p className="text-2xl crimson-font font-bold">
                <span className="text-gray-200 dark:text-gray-700">
                  example
                </span>
                .net
              </p>
              <div>
                <p className="text-xs">Starts at:</p>
                <span className="font-semibold text-2xl crimson-font text-[#1da1fc]">
                  &#x20A6; 0.00{" "}
                  <span className="text-sm font-normal text-gray-600">
                    {" "}
                    /month
                  </span>
                </span>
              </div>
            </div>
            <div className="bg-white dark:bg-[#ffffff08] border border-t-orange-500 dark:border-t-orange-500 dark:border-[#c4c4c424] border-t-4 rounded-md p-5 grid gap-4">
              <span className="text-xs bg-orange-100 dark:bg-orange-100/5 text-orange-500 p-2 rounded w-fit">
                🔥 Popular
              </span>
              <p className="text-2xl crimson-font font-bold">
                <span className="text-gray-200 dark:text-gray-700">
                  example
                </span>
                .com.ng
              </p>
              <div>
                <p className="text-xs">Starts at:</p>
                <span className="font-semibold text-2xl crimson-font text-[#1da1fc]">
                  &#x20A6; 0.00{" "}
                  <span className="text-sm font-normal text-gray-600">
                    {" "}
                    /month
                  </span>
                </span>
              </div>
            </div>
            <div className="bg-white dark:bg-[#ffffff08] border border-t-orange-500 dark:border-t-orange-500 dark:border-[#c4c4c424] border-t-4 rounded-md p-5 grid gap-4">
              <span className="text-xs bg-orange-100 dark:bg-orange-100/5 text-orange-500 p-2 rounded w-fit">
                👑 King of domains
              </span>
              <p className="text-2xl crimson-font font-bold">
                <span className="text-gray-200 dark:text-gray-700">
                  example
                </span>
                .com
              </p>
              <div>
                <p className="text-xs">Starts at:</p>
                <span className="font-semibold text-2xl crimson-font text-[#1da1fc]">
                  &#x20A6; 0.00{" "}
                  <span className="text-sm font-normal text-gray-600">
                    {" "}
                    /month
                  </span>
                </span>
              </div>
            </div>
            <div className="bg-white dark:bg-[#ffffff08] border border-t-[#68A5AE] dark:border-t-[#68A5AE] dark:border-[#c4c4c424] border-t-4 rounded-md p-5 grid gap-4">
              <span className="text-xs bg-brandGreen dark:bg-brandGreen/5 text-[#68A5AE] p-2 rounded w-fit">
                👍🏽 Domain you can trust
              </span>
              <p className="text-2xl crimson-font font-bold">
                <span className="text-gray-200 dark:text-gray-700">
                  example
                </span>
                .org
              </p>
              <div>
                <p className="text-xs">Starts at:</p>
                <span className="font-semibold text-2xl crimson-font text-[#1da1fc]">
                  &#x20A6; 0.00{" "}
                  <span className="text-sm font-normal text-gray-600">
                    {" "}
                    /month
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="mb-4">We offer:</p>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 items-end">
            <div className="md:col-span-2 lg:col-span-3 grid gap-4">
              <div className="text-sm grid gap-1">
                <p>1. Extensive Domain Options</p>
                <p className="text-gray-500">
                  Choose from a wide variety of domain extensions, including
                  .com, .net, .org, and many more. Find the perfect domain that
                  suits your business or personal brand.
                </p>
              </div>
              <div className="text-sm grid gap-1">
                <p>2. WHOIS Privacy Protection</p>
                <p className="text-gray-500">
                  Keep your personal information private with our WHOIS Privacy
                  Protection service. Protect your details from spammers,
                  marketers, and identity thieves.
                </p>
              </div>
              <div className="text-sm grid gap-1">
                <p>3. 24/7 Customer Support</p>
                <p className="text-gray-500">
                  Our dedicated support team is available around the clock to
                  assist you with any domain-related queries or issue.
                </p>
              </div>
              <div className="text-sm grid gap-1">
                <p>4. Competitive Pricing</p>
                <p className="text-gray-500">
                  Get the best value for your money with our affordable domain
                  registration fees. Enjoy premium features without breaking the
                  bank.
                </p>
              </div>
              <div className="text-sm grid gap-1">
                <p>5. Security Registration</p>
                <p className="text-gray-500">
                  We use industry-standard encryption and security protocols to
                  protect your domain registration process, ensuring your
                  details remain safe.
                </p>
              </div>
            </div>
            <div className="md:col-span-1 lg:col-span-2">
              <img src={domainImage} alt="" />
            </div>
          </div>
        </div>
        <div>
          <p className="mb-4">Why should i get a domain name?:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
            <div className="text-sm">
              <p>Establish Your Omline</p>
              <p className="text-gray-500 mt-2">
                A domain name is your unique address on the internet. It helps
                you create a professional online presence, making it easier for
                customers and visitors to find you.
              </p>
            </div>
            <div className="text-sm">
              <p>Enhance Credibility</p>
              <p className="text-gray-500 mt-2">
                Having your own domain name gives your business or personal
                brand a sense of legitimacy and trustworthiness. It shows that
                you are serious and invested in your online presence.
              </p>
            </div>
            <div className="text-sm grid">
              <p>Brand Recognition</p>
              <p className="text-gray-500 mt-2">
                A memorable domain name helps in building your brand identity.
                It makes it easier for people to remember and return to your
                site, fostering brand loyalty.
              </p>
            </div>
            <div className="text-sm">
              <p>Improve Search Engine Rankings</p>
              <p className="text-gray-500 mt-2">
                A relevant domain name can improve your website's SEO, making it
                easier for people to find you through search engines. This can
                lead to increased traffic and better visibility online.
              </p>
            </div>
            <div className="text-sm">
              <p>Personalized Email Address</p>
              <p className="text-gray-500 mt-2">
                With your own domain, you can create custom email addresses that
                match your domain name{" "}
                <span className="text-primaryBlue">
                  (e.g., yourname@yourdomain.com)
                </span>
                . This adds a professional touch to your communications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyDomain;
